import React from "react";
import { ButtonBack, ButtonNext, CarouselProvider, Slider, Slide, Dot, } from "pure-react-carousel";
import ImgChevronNext from "../images/ico-hero-next.png";
import ImgChevronPrev from "../images/ico-hero-prev.png";
import { buildFluidImageData } from "@imgix/gatsby";
import { Link } from "gatsby";

export default class HomePageSlider extends React.Component {

  constructor(props) {

    super(props);
    this.state = { visibleSlides: props.data.length, carouselHeight: null, };
    this.handleWindowResize = this.handleWindowResize.bind(this)
    setTimeout(() => {
      this.handleWindowResize()
    })
  }

  componentDidMount() {
    typeof window !== "undefined" &&
      window.addEventListener("resize", this.handleWindowResize)
  }

  componentWillUnmount() {
    typeof window !== "undefined" &&
      window.removeEventListener("resize", this.handleWindowResize)
  }

  handleWindowResize() {
    if (typeof window !== "undefined") {
      if (window.innerWidth > 768) {
        this.setState({ visibleSlides: this.props.data.length })
      } else {
        this.setState({ visibleSlides: 1 })
      }
    }
  }

  render() {
    return (
      
      <CarouselProvider
        totalSlides={this.props.data.length}
        visibleSlides={1}
        isIntrinsicHeight={true}
        isPlaying={true}
        infinite={true}
        interval={5000}
      >
        <section className="hero">
          <div>
            <div className="hero__top">
              <div className="slider slider-hero">
                <div>
                  <div style={{ width: "100%" }}>
                    <Slider style={{ width: "100%" }}>
                     

                    {this.props.data.map((item, i) => {

                      // TODO: need to use a proper background image component

                      const image = buildFluidImageData(
                        item.image[0].url,
                        {
                          ar: item.image[0].width/item.image[0].height,
                          auto: ["format", "compress"], 
                        },
                        {
                          sizes: "100vw", 
                        }
                      );


                      // const uniqueId = 
                      
                      // <ImgixFluidImage imageUrl={image.url} sizes={sizes} ar={ar||3/2} focalPoint={image.focalPoint} />
                          return (
                            <Slide
                            key={i}
                            style={{
                              backgroundImage: "url(" + image.src +")",
                              backgroundSize: "cover",
                            }}
                          >
                          <div className="shell hero-slide-shell">
                            <div>
                            <div className="article-slider text-lato">
                              <h1 className="h1 article__title text-white" dangerouslySetInnerHTML={{ __html: item.head }} />
                               
                              <p className="article__subtitle h1 text-white carousel-subheading" dangerouslySetInnerHTML={{ __html: item.subhead }} />

                              {item.customUrl || item.relatedEntry.length > 0 ?
                                item.customUrl ? <a className="article__link link-more link-more--white text-lato" href={item.customUrl}>{item.linkText ? item.linkText : "Read more"}</a> : item.relatedEntry.length && <Link className="article__link link-more link-more--white text-lato" to={`/${item.relatedEntry[0].uri}`}>{item.linkText ? item.linkText : "Read more"}</Link>
                                :
                                <></>
                              }

                              <br/>

                              {item.customUrl2 || item.relatedEntry2.length > 0 ?
                                item.customUrl2 ? <a className="article__link link-more link-more--white text-lato" href={item.customUrl2}>{item.linkText2 ? item.linkText2 : "Read more"}</a> : item.relatedEntry2.length && <Link className="article__link link-more link-more--white text-lato" to={`/${item.relatedEntry2[0].uri}`}>{item.linkText2 ? item.linkText2 : "Read more"}</Link>
                                :
                                <></>
                              }
                            </div>
                          </div>
                        </div>
                      </Slide>

                          )
                        })}
                     
                    </Slider>
                  </div>
                </div>
                <ButtonBack
                  className="swiper-button-prev slider__btn slider__btn--prev"
                  tabIndex={0}
                  role="button"
                  aria-label="Previous slide"
                  aria-disabled="false"
                >
                  <div>
                    <img src={ImgChevronPrev} alt="" className="w-6 h-6"/>
                  </div>
                </ButtonBack>
                <ButtonNext
                  className="swiper-button-next slider__btn slider__btn--next"
                  tabIndex={-1}
                  role="button"
                  aria-label="Next slide"
                  aria-disabled="false"
                >
                  <div>
                    <img src={ImgChevronNext} alt="" className="w-6 h-6"/>
                  </div>
                </ButtonNext>
              </div>
            </div>
            {this.props.alert ? 
            <div className="hero__bottom">
              <div className="shell">
                <div className="hero__bottom-inner">
                  <ul className={`articles-sm ${"article-sm-" + this.props.data.length.toString()}`}>
                  {this.props.data.map((item, i) => {

                    return(
                      <li key={item.id}>
                      <Dot slide={i} className="article-sm text-white">
                        <div className="article__entry">
                          <p className="article__title">
                            {item.navText}
                          </p>
                        </div>
                      </Dot>
                    </li>
                    )

                  })}
                  </ul>
                  
                    <div className="message">
                      <div className="shell">
                        <div className="message__entry richtext-entry px-4">
                          <div dangerouslySetInnerHTML={{ __html: this.props.alert, }}/>
                        </div>
                      </div>
                    </div>
                    
                  
                </div>
              </div>
            </div>
            :
            <></>
          }
          </div>
        </section>
      </CarouselProvider>
    )
  }
}
