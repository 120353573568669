import React from "react";

const EditLink = ({ sectionHandle, remoteId }) => {

  const currentHostname = typeof window !== `undefined` ? window.location.hostname : "nohostname";

  function handleClick(e) {
    e.preventDefault();
    // obfuscated admin link - just for crawlers to not crawl the CMS
    const url = "aHR0cHM6Ly9nZW5lc2lzMi50ZXN0LmZsb3dyb290MTcuZmxvd3NhLmRldi9hZG1pbi9lbnRyaWVzLw==";
    if (typeof window !== `undefined`) window.location = atob(url) + sectionHandle + "/" + remoteId;
  }

  if (currentHostname === "localhost" || currentHostname === "genesis2.gatsbyjs.io" || currentHostname === "genesis2.gtsb.io" || currentHostname === "preview-genesis2.gtsb.io") {
    return (<a href="/" onClick={handleClick}>Edit</a>);
  } else return (<></>);
}

export default EditLink;